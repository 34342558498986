<template>
    <div class="cardReissue">
        <div class="left-box">
            <div class="title">挂失卡会员信息</div>
            <div class="tr-li card-tr">
                <div class="lable-txt">卡号：</div>
                <div class="from-input">
                    <div class="card-no"><input type="text" keyBoard placeholder="请输入卡号或读卡" v-model="cardNo" @keyup.enter="selectReadCard()" maxlength="20"/><i class="iconfont icon-sousuo" @click="selectReadCard()" ></i></div> 
                    <div class="read-card" @click="readCard()">读卡</div>    
                </div>
            </div>
            <div class="info-box">
                <div class="title">客户资料信息</div>
                <div class="tr-li">
                    <div class="lable-txt">会员等级：</div>
                    <div class="from-input"><input type="text" readonly v-model="memberInfo.Bestech_Card_KindName" /></div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">客户姓名：</div>
                    <div class="from-input"><input type="text" readonly v-model="memberInfo.MemberName" /></div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">手机号：</div>
                    <div class="from-input"><input type="text" readonly v-model="memberInfo.Mobile" /></div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">生日：</div>
                    <div class="from-input"><input type="text" readonly v-model="memberInfo.Birthday" /></div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">性别：</div>
                    <div class="from-input"><input type="text" readonly v-model="memberInfo.Gender"/></div>
                </div>
                <div class="title">卡资产信息</div>
                <div class="tr-li">
                    <div class="lable-txt">余额：</div>
                    <div class="from-input"><input class="text-right" type="text" readonly v-model="memberInfo.Card_Money"/>
                        <p class="describe" :style="memberInfo?'':'visibility: hidden'">其中赠送金额{{memberInfo.Gift_Money}}元</p>
                    </div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">积分：</div>
                    <div class="from-input"><input class="text-right" type="text" readonly v-model="memberInfo.Card_PilePoint"/>
                    </div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">券：</div>
                    <div class="from-input ticket" @click="ticketClick()"><input class="text-right" :value="(memberInfo?.Coupons?.length||'0')+'张'" type="text" readonly/><i class="iconfont">></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="right-box">
            <div class="scroll-box">
                <div class="title">补卡新卡信息</div>
                <div class="tr-li">
                    <div class="lable-txt">卡号：</div>
                    <div class="from-input">
                        <div class="card-no input-box"><input keyBoard type="text" placeholder="请输入卡号或读卡" v-model="newCardNo" @keyup.enter="newSelectReadCard()" maxlength="20"/><i class="iconfont icon-sousuo" @click="newSelectReadCard()"></i></div> 
                        <div class="read-card" @click="readNewCard()">读卡</div>    
                    </div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">工本费</div>
                    <div class="from-input"><div class="input-box"><input keyBoard type="text" placeholder="请输入工本费" v-model="costMoney" oninput="value=value.replace(/[^\d.]/g,'')" /></div></div>
                </div>
                <div class="info-box">
                    <div class="title">付款方式</div>
                    <div class="pay-box">
                        <div class="pay-scroll"  v-scroll-anime="{up:'pay-up',down:'pay-down',liClass:'.item-li',marginH:8,isRow:false}">
                            <div class="item-li" :class="{selected:item.PAY_ID==SelectPayType.PAY_ID}" :aria-disabled="!memberInfo.Bestech_Card_KindID"  v-for="item in payType" :key="item" @click="payClick(item)">
                                {{item.PAY_NAME}}<i class="iconfont icon-gou1"></i>
                            </div> 
                            <div class="item-li" style="visibility: hidden;"></div>
                        </div>
                        <div class="item-operation">
                            <div class="bnt-fangxiang" ref="pay-up"><i class="iconfont icon-fangxiang-up"></i></div>
                            <div class="bnt-fangxiang" ref="pay-down"><i class="iconfont icon-fangxiang-down"></i></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer-box">
                <button class="btn" @click="back()">返回</button>
                <button class="btn selected" @click="recordShow=true">查看补卡记录</button>
                <button class="btn selected" @click="makeCard()">确定补卡</button>
            </div>
        </div>
        <!-- 券列表 -->
        <modal-load :isShow="ticketShow">
            <ticket-model :isShow="ticketShow" @closeModel="ticketShow=false" :Coupons="memberInfo.Coupons"></ticket-model>
        </modal-load> 
        <!-- 充值记录 -->
        <modal-load :isShow="recordShow">
            <recharge-record-model :isShow="recordShow" @closeModel="recordShow=false" :crmOpenCard="8"></recharge-record-model>
        </modal-load> 
        <!--聚合支付-->
        <modal-load :isShow="scanCodeShow">
            <scanCodePay-model :isShow="scanCodeShow" :SelectPlanDetail="SelectPlanDetail" :Cardtype='2' :ispaySuccess="ispaySuccess" :CrmBizID="CrmBizID" @scanCodeconfirm="scanCodeconfirm" @scanCodecloseModel="scanCodecloseModel" ></scanCodePay-model>
        </modal-load> 
        <!-- 选择卡信息 -->
        <modal-load :isShow="cardMemberShow">
            <select-card-member-info :isShow="cardMemberShow" @closeModel="cardMemberShow=false" :data="cardMemberList" @confirm="selectCardInfo"></select-card-member-info>
        </modal-load>
        <!--押金支付-->
        <modal-load :isShow="preMoneyShow">
            <member-pre-money-model :isShow="preMoneyShow" :rechargeMoney="rechargeMoney" 
                @close="preMoneyShow=false" @confirm="preMoneyConfirm" />
        </modal-load> 
    </div>
</template>

<script>
import { ticketModel,scanCodePayModel,rechargeRecordModel,selectCardMemberInfo,memberPreMoneyModel } from '../model'
import { newGuid } from '/src/common/index.js'

/**补办卡 */
export default {
    name:'cardReissue',
    components:{ticketModel,scanCodePayModel,rechargeRecordModel,selectCardMemberInfo,memberPreMoneyModel},
    data(){
        return {
            /**卡号 */
            cardNo:'',
            /**内卡号 */
            cardSNR:'',
            /**卡id */
            card_AutoID:'',
            /**补办的新卡号 */
            newCardNo:'',
            /**新卡id */
            newCard_AutoID:'',
            /**券列表 */
            ticketShow:false,
            /**选择卡信息 */
            cardMemberShow:false,
            /**多个卡信息 */
            cardMemberList:[],
            /**聚合支付 */
            scanCodeShow:false,
            /**充值记录 */
            recordShow:false,
            /**获取用户*/
            userInfo:'',
            /**支付方式 */
            payType:Object,
            /**选中的支付方式 */
            SelectPayType:'',
            /**会员信息 */
            memberInfo:'',
            /**券数量 */
            couponsNum:'',
            /**工本费 */
            costMoney:'',
            SelectPlanDetail:'',
            /**是否付款成功 */
            ispaySuccess:0,
            /**会员业务流水ID, 如开卡支付的传 OpenCard_BizID 的值, 会员充值支付的传Recharge_BizID的值 */
            CrmBizID:'',
            //押金支付 弹层
            preMoneyShow:false,
        }
    },
    watch:{
        cardNo(){
            this.memberInfo='';
        }
    },
    mounted(){
        this.$nextTick(()=> {
            this.userInfo=this.$auth.getUserInfo();
            this.$httpAES.post('Bestech.CloudPos.GetMemberPayments',{}).then((data)=>{
                if(data.ResponseHeader.ResultCode!=0){
                    this.$message.error(data.ResponseHeader.ResultDesc);
                }else{
                    this.payType=(data.ResponseBody||[]).filter(it=>it.INTERFACE_TYPE!='11');//排除押金
                }
            }).catch(()=>{
                this.$alert('获取支付方式失败', "提示", {confirmButtonText: "确定"});
            });
        })
    },
    methods:{
        /**退出 */
        back(){
            this.$emit("close");
        },
        /**点击 读卡 */
        readCard(){
            this.$webBrowser.redCardM1().then((d)=>{
                if(d){
                    if(d.state==0){
                        this.card_AutoID=d.data.cardID;
                        this.cardNo=d.data.cardNo;
                        this.cardSNR=d.data.snr
                        this.selectReadCard()
                    }else{
                        this.$message.error('卡信息读取失败：'+d.message);
                    }
                }
            })
        },
        /**点击 读新卡 */
        readNewCard(){
            this.$webBrowser.redCardM1().then((d)=>{
                if(d){
                    if(d.state==0){
                        this.newCard_AutoID=d.data.cardID;
                        this.newCardNo=d.data.cardNo;
                        this.cardSNR=d.data.snr
                    }else{
                        this.$message.error('卡信息读取失败：'+d.message);
                    }
                }
            })
        },
        payClick(item){
            this.SelectPayType = item
        },
        ticketClick(){
            if(this.memberInfo!=''){
                this.ticketShow = true
            }
        },
        /**读取挂失会员信息 */
        selectReadCard(){
            if(this.cardNo=='' && !this.cardSNR){
                this.$message.warning('请填写挂失会员卡号')
                return
            }
            const loading = this.$loading({
                text: "读取挂失会员信息中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.GetMemberInfo",{
                Body_ID:newGuid(),
                User_ID:this.userInfo?.User_ID,
                Business_Sense:3,//业务场景 3：挂失，取消挂失，补卡，退卡
                DynamicNo:this.cardNo,
                Card_AutoID:this.card_AutoID,
                Card_SN:this.cardSNR,
                IsGetNotIssuedCard:true,
            }).then((data)=>{
                loading.close();
                if(data.ResponseHeader.ResultCode==0){
                    this.cardSNR=''
                    this.card_AutoID=''
                    if(data.ResponseBody.length>1){
                        this.cardMemberList=data.ResponseBody;
                        this.cardMemberShow=true;
                    }else{
                        this.selectCardInfo(data.ResponseBody[0])
                    }
                }else{
                    this.$message.error("挂失会员信息读取失败："+data.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('挂失会员信息读取失败：'+e);
                console.log('挂失会员信息读取失败：'+e);
            })
        },
        /**多个卡信息时选择一个 */
        selectCardInfo(data){
            this.cardMemberShow=false;
            this.cardNo=data.Card_No;
            this.$nextTick(()=>{
                this.memberInfo = data;
                if(this.memberInfo.Gender==0){
                    this.memberInfo.Gender='男'
                }else{
                    this.memberInfo.Gender='女'
                }
            })
        },
        /**读取补卡新会员信息 */
        newSelectReadCard(){
            if(this.newCardNo==''){
                this.$message.warning('请填写新卡卡号')
                return
            }
            const loading = this.$loading({
                text: "读取新会员信息中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.GetMemberInfo",{
                Body_ID:newGuid(),
                User_ID:this.userInfo?.User_ID,
                DynamicNo:this.newCardNo,
                IsGetNotIssuedCard:true,
            }).then((data)=>{
                loading.close();
                console.log('新会员信息:'+JSON.stringify(data))
                if(data.ResponseHeader.ResultCode==0){
                    this.newCard_AutoID =data.ResponseBody[0]?.Bestech_Card_AutoID
                }else{
                    this.$message.error("会员信息读取失败："+data.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('会员信息读取失败：'+e);
                console.log('会员信息读取失败：'+e);
            })
        },
        /**确认补卡 */
        makeCard(data){
            if(this.memberInfo=='' && this.newCard_AutoID==''){
                 this.$message.warning('请确认卡号数据准确')
                 return
            }
            let param={
                Body_ID:newGuid(),
                User_ID:this.userInfo?.User_ID,
                Card_AutoID:this.memberInfo.Bestech_Card_AutoID, //卡主键
                Card_No:this.memberInfo.Card_No, //卡号
                New_Card_AutoID:this.newCard_AutoID, //新卡的主键
                New_Card_No:this.newCardNo,//新卡号
                Member_Name:this.memberInfo.MemberName, //会员姓名
                Mobile:this.memberInfo.Mobile, //手机号
                Rpt_Date:this.userInfo?.Rpt_Date, //营业日期
                Operate_User:this.userInfo?.Login_Name,//操作员
                Operate_PosName:this.userInfo?.Site_Name, //站点
                ReissueCard_BizID:''
            }
            if(this.costMoney!=''){
                this.SelectPlanDetail={ Recharge_Money:Number(this.costMoney)}
                if(this.SelectPayType.PAY_NAME=="聚合支付" && this.scanCodeShow==false){
                    this.scanCodeShow=true
                    return
                }else if(this.SelectPayType.INTERFACE_TYPE=='11' && !this.preMoneyShow){//预付金，押金
                    this.preMoneyShow=true;
                    return;
                }
                Object.assign(param,{
                    Cost_Money:this.costMoney, //工本费
                    Payment_Type:this.SelectPayType.INTERFACE_TYPE,//支付方式类型
                    Payment_Code:this.SelectPayType.PAY_CODE,//付款方式编号
                    Recharge_PayID:this.SelectPayType.PAY_ID,//充值支付方式ID
                })
            }
            Object.assign(param,data);
            const loading = this.$loading({
                text: "补卡信息验证中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            console.log('补卡传参:'+JSON.stringify(param))
            this.$httpAES.post("Bestech.CloudPos.CardReissue",param).then((data)=>{
                loading.close();
                console.log('验证返回信息:'+JSON.stringify(data))
                if(data.ResponseHeader.ResultCode==0){
                    this.CrmBizID = data.ResponseBody.Recharge_BizID;
                    this.ispaySuccess=0
                    if(this.scanCodeShow){
                        this.scanCodeShow=false;
                    }else if(this.preMoneyShow){
                        this.preMoneyShow=false;
                    }
                    this.CardReissueSubmit(data)
                }else{
                    if(data.ResponseHeader.ResultCode>0 && this.scanCodeShow==true){
                        this.CrmBizID = data.ResponseBody?.Recharge_BizID;
                        if(data.ResponseHeader.ResultCode==4003){
                            this.ispaySuccess=0
                            this.$message.error(data.ResponseHeader.ResultDesc);
                        }else if(data.ResponseHeader.ResultCode==4001){
                            this.ispaySuccess=1
                            this.$message.error(data.ResponseHeader.ResultDesc);
                        }else{
                            this.ispaySuccess=1
                            this.$message.error(data.ResponseHeader.ResultDesc);
                        }
                    }else{
                        this.ispaySuccess=0
                        this.reload()
                        this.$message.error(data.ResponseHeader.ResultDesc);
                    }
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('补卡信息验证失败：'+e);
                console.log('补卡信息验证失败：'+e);
            })
        },
        /**补卡提交 */
        CardReissueSubmit(data){
            let param={
                Body_ID:newGuid(),
                User_ID:this.userInfo?.User_ID,
                ReissueCard_BizID:data.ResponseBody.ReissueCard_BizID, //流水号
                Operate_User:this.userInfo?.Login_Name,//操作员
                Operate_PosName:this.userInfo?.Site_Name, //站点
                Payment_Code:this.SelectPayType.PAY_CODE, //付款方式编号
                WX_Pay_SerialNo:data.ResponseBody.WX_Pay_MchID //微信支付成功流水号
            }
            const loading = this.$loading({
                text: "会员补卡提交中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            console.log('提交参数:'+JSON.stringify(param))
            this.$httpAES.post("Bestech.CloudPos.CardReissueSubmit",param).then((data)=>{
                loading.close();
                console.log('会员补卡返回信息:'+JSON.stringify(data))
                if(data.ResponseHeader.ResultCode==0){
                    this.$message.success("会员补卡完成");
                    this.reload()
                    console.log('会员补卡完成')
                }else{
                    this.$message.error("会员补卡失败："+data.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('会员补卡失败：'+e);
                console.log('会员补卡失败：'+e);
            })
        },
        /**押金支付返回 */
        preMoneyConfirm(param){
            this.makeCard({
                DepositRechargePayments:param//押金单支付列表，选押金支付时必传
            });
        },
        /**聚合支付返回 */
        scanCodeconfirm(payCode){
            this.makeCard({
                SsPay_QrCode:payCode, //聚合支付二维码信息
            })
        },
        /**聚合支付关闭 */
        scanCodecloseModel(type){
            //type:0 关闭聚合支付弹窗 1不可关闭 2关闭聚合支付弹窗并去执行OpenActualCardDirectSubmit方法
            if(type==0){
                this.ispaySuccess=0
                this.scanCodeShow=false
            }else if(type==5){
                this.ispaySuccess=0
                this.scanCodeShow=false
                this.reload()
            }else{
                this.ispaySuccess=1
            }
        },
        /*刷新当前页面 */
        reload() {
             this.$emit("reload");
            //this.$forceUpdate();
        }
    }
}
</script>

<style lang="scss">
@import "./cardReissue.scss";
</style>